//hamburger menu
function createHamburgerMenu () {

  var $win = $(window);
  var ww = window.innerWidth;
  var currentWidth = window.innerWidth;
  var breakpoint = 1024;
  var breakpointSm = 768;


  navTabChange();
  anchorClick ();

  // ---  横幅が変動した時に実行
  $win.on('load', function () {
    checkGnavWidth();
  });

  window.addEventListener("resize", function () {
    // ウインドウ横幅が変わっていない場合
    if ( currentWidth == window.innerWidth ) {
      return false;
    }
    checkGnavWidth();
    currentWidth = window.innerWidth; // 横幅を更新

  });

  // -- toggle action
  $('.c-toggleBtn').on('click', function () {
    $(this).toggleClass('is-toggleActive');
    $('html').toggleClass('open');
    $('.l-togglenav').toggleClass('is-open');



  });

  //アンカーリンクをクリックしたらメニュー閉じる
  function anchorClick () {
    $('.l-togglenav__item').not('.has-dropdown').on('click', function() {
      $('.c-toggleBtn').removeClass('is-toggleActive');
      $('.l-togglenav').removeClass('is-open');
    });
    $('.l-dropDown__item >a').on('click', function() {
      $('.l-dropDown').removeClass('is-open');
    });
  }

  function checkGnavWidth () {
    ww = window.innerWidth;
    globalNavInit();
  }

  function dropDown() {
    $(".l-navigation__item.has-dropdown > a").on('click', function(e){
      e.preventDefault();
      var target = $(this).next('.l-dropDown');
      if (target.hasClass('is-open')) {
        target.removeClass('is-open');
      } else {
        target.addClass('is-open');
      }
    });
  }
  // --- リサイズ時の初期化
  function globalNavInit () {
    if( ww >= breakpoint ) {
      //PC挙動
      dropDown();
      //$(".l-navigation__item.has-dropdown > a").on('click', function(e){
      //  e.preventDefault();
      //  var target = $(this).next('.l-dropDown');
      //  if (target.hasClass('is-open')) {
      //    target.removeClass('is-open');
      //  } else {
      //    target.addClass('is-open');
      //  }
      //});
    } else if ( ww > breakpointSm && ww < breakpoint ) {
      dropDown();
    }else {
      $(".l-togglenav__item.has-dropdown > a").on('click', function(e){
        e.preventDefault();
        if ($('.l-dropDown--togglenav').hasClass('is-open')) {
          $('.l-dropDown--togglenav').removeClass('is-open');
        } else {
          $('.l-dropDown--togglenav').addClass('is-open');
        }
      });
      dropDown();
    }
  }

  //Gナビのアンカーリンクを検出し、タブ切り替え
  function navTabChange() {
    var hash = $(location).prop('hash').substr(1);
    if (hash.length) {
      $("body,html").animate({scrollTop: 0}, 500);
      $('.p-productCat__button').removeClass('p-productCat__button--current');
      $('.c-productlist').removeClass('c-productlist--active');
      $('.p-productCat__button[data-tab="' + hash + '"]').addClass('p-productCat__button--current');
      $('.c-productlist#' + hash).addClass('c-productlist--active');
    } else {
    }
    //アンカーリンクをクリックしたら再描画(同ページでアンカーをクリック時タブ切り替えしないバグを防ぐ)
    $('.l-dropDown__item > a[href*="#"],.l-dropDown--togglenav > .l-togglenav__item > a[href*="#"]').on('click', function() {
      var href= jQuery(this).attr("href");
      window.location.reload(true);
      window.location.href = "../../"+href;
    });
  }
}